import { router } from "~/router";
import { generateHexUuid } from "~/utilities/generate-hex-uuid";
import { isEmptyObject } from "~/utilities/is-empty-object";

import { type PageviewEvent } from "../../types/application/analytics";
import { postPageview } from "../api/analytics/page-view";
import { applicationContext } from "../context/application-context";
import { logError } from "../external/logging";

import { getSessionId } from ".";

let isFirstPageview = true;
let pageviewId: string;

export const getPageviewId = () => pageviewId;

const resetPageviewId = () => {
	pageviewId = generateHexUuid();
};

const getResolution = (): string => {
	return `${window.innerWidth}x${window.innerHeight}`;
};

const getTitle = () => {
	const matches = router.state.matches;
	for (const match of matches.slice().reverse()) {
		if (!isEmptyObject(match.staticData) && match.staticData.analyticsTitle) {
			return match.staticData.analyticsTitle;
		}
		if (match.context.analyticsTitle) {
			return match.context.analyticsTitle();
		}
		if (!isEmptyObject(match.staticData) && match.staticData.crumb) {
			return match.staticData.crumb();
		}
		const title = match.context.breadcrumbs?.crumb?.();
		if (title) {
			return title;
		}
	}
	return document.title;
};

router.subscribe("onResolved", async (event) => {
	try {
		// eslint-disable-next-line @typescript-eslint/no-unused-expressions -- we want to check to see if the application content has a tenant set, it will throw if not
		applicationContext.tenant;
	} catch {
		return;
	}
	if (!event.pathChanged && !isFirstPageview) return;

	try {
		resetPageviewId();
		const url = new URL(window.location.href);
		const baseUrl = `${url.protocol}//${url.host}`;

		const pageview = {
			connectionType: applicationContext.connection.connectionType,
			embeddedPlatform: "none",
			id: pageviewId,
			resolution: getResolution(),
			resource: `${baseUrl}${event.toLocation.href}`,
			timestamp: Date.now(),
			title: getTitle(),
			visit: getSessionId(),
		} as PageviewEvent;

		if (isFirstPageview) {
			if (document.referrer) {
				pageview.referrer = document.referrer;
			}
			isFirstPageview = false;
		}

		await postPageview(pageview);
	} catch (error) {
		logError(error, { extra: { debugInfo: "Analytics - page view" } });
	}
});
