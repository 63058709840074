/**
 * Routes for the profile page.
 */

import { nprogress } from "@mantine/nprogress";
import { createRoute, lazyRouteComponent, redirect, useNavigate } from "@tanstack/react-router";
import { zodSearchValidator } from "@tanstack/router-zod-adapter";
import { t } from "i18next";
import { z } from "zod";

import { interestsSearchSchema } from "~/lib/api/interests/interests.schema";
import { globalConfig } from "~/lib/config";

import { applicationLayout } from "./application";
import { dashboardRoute } from "./global";

export const profileRoute = createRoute({
	getParentRoute: () => applicationLayout,
	notFoundComponent: () => {
		// eslint-disable-next-line react-hooks/rules-of-hooks -- This is a component, but eslint doesn't recognise it
		const navigate = useNavigate();

		navigate({
			params: profileRoute.useParams(),
			search: {
				tab: "completed",
			},
			to: profileRoute.to,
		});

		return null;
	},

	path: "profile",
	staticData: {
		analyticsTitle: "Profile",
		crumb: () => t("navigation.profile.root"),
	},
});

export const profileIndexRoute = createRoute({
	beforeLoad: () => {
		nprogress.start();
	},
	component: lazyRouteComponent(() => import("~/pages/Profile"), "ProfilePage"),
	getParentRoute: () => profileRoute,
	loader: () => {
		nprogress.cleanup();
	},
	onEnter: () => {
		nprogress.complete();
	},
	onStay: () => {
		nprogress.reset();
	},
	path: "/",
	validateSearch: z.object({
		tab: z
			.union([z.literal("completed"), z.literal("bookmarks")])
			.optional()
			.default("completed"),
	}),
});

export const awardsRoute = createRoute({
	beforeLoad: () => {
		nprogress.start();
	},
	getParentRoute: () => profileRoute,
	loader: () => {
		nprogress.cleanup();
	},
	notFoundComponent: () => {
		// eslint-disable-next-line react-hooks/rules-of-hooks -- This is a component, but eslint doesn't recognise it
		const navigate = useNavigate();

		navigate({
			params: awardsRoute.useParams(),
			to: awardsRoute.to,
		});

		return null;
	},
	onEnter: () => {
		nprogress.complete();
	},
	path: "awards",
	staticData: {
		analyticsTitle: "Awards",
		crumb: () => t("navigation.profile.awards"),
	},
});

export const awardsIndexRoute = createRoute({
	beforeLoad: () => {
		nprogress.start();
	},
	component: lazyRouteComponent(() => import("~/pages/Profile/Awards"), "AwardsPage"),
	getParentRoute: () => awardsRoute,
	loader: () => {
		nprogress.cleanup();
	},
	onEnter: () => {
		nprogress.complete();
	},
	path: "/",
});

export const interestsRoute = createRoute({
	beforeLoad: ({ params }) => {
		nprogress.start();

		if (!globalConfig.isFeatureEnabled("enableInterests")) {
			throw redirect({
				params,
				to: dashboardRoute.to,
			});
		}
	},
	component: lazyRouteComponent(() => import("~/pages/Profile/Interests/Interests"), "InterestsPage"),
	getParentRoute: () => profileRoute,
	loader: () => {
		nprogress.cleanup();
	},
	notFoundComponent: () => {
		// eslint-disable-next-line react-hooks/rules-of-hooks -- This is a component, but eslint doesn't recognise it
		const navigate = useNavigate();

		navigate({
			params: interestsRoute.useParams(),
			to: interestsRoute.to,
		});

		return null;
	},
	onEnter: () => {
		nprogress.complete();
	},
	onStay: () => {
		nprogress.reset();
	},
	path: "interests",
	staticData: {
		analyticsTitle: "Interests",
		crumb: () => t("navigation.profile.interests"),
	},
	validateSearch: zodSearchValidator(interestsSearchSchema),
});

export const interestsIndexRoute = createRoute({
	beforeLoad: () => {
		nprogress.start();
	},
	component: lazyRouteComponent(() => import("~/pages/Profile/Interests/Interests"), "InterestsPage"),
	getParentRoute: () => interestsRoute,
	loader: () => {
		nprogress.cleanup();
	},
	onEnter: () => {
		nprogress.complete();
	},
	path: "/",
});
