import { use, t } from "i18next";
import { initReactI18next } from "react-i18next";
import { z } from "zod";
import { makeZodI18nMap } from "zod-i18n-map";

import { DEFAULT_LANGUAGE } from "~/lib/constants/language";

import { resources } from "./resources";

// eslint-disable-next-line react-hooks/rules-of-hooks -- it's not a hook but the rule thinks it is due to is being called use
use(initReactI18next).init({
	debug: import.meta.env.DEV && import.meta.env.MODE !== "test",
	fallbackLng: DEFAULT_LANGUAGE,
	interpolation: {
		escapeValue: false,
	},
	lng: DEFAULT_LANGUAGE,
	load: "all",
	lowerCaseLng: false,
	resources,
	returnNull: false,
});

z.setErrorMap(makeZodI18nMap({ t }));
