import { NapierBridgeCommon } from "@evotix/napier-ui-common-native-bridge";
import { createRoute, lazyRouteComponent, useNavigate } from "@tanstack/react-router";

import { Legal } from "~/enums";

import { privateRoute } from "./private";

export const legalLayout = createRoute({
	beforeLoad: NapierBridgeCommon.setStatusBarLight,
	component: lazyRouteComponent(() => import("~/layouts/Legal"), "LegalLayout"),
	getParentRoute: () => privateRoute,
	path: "legal",
});

export const termsAndConditionsRoute = createRoute({
	getParentRoute: () => legalLayout,
	loader: async () => {
		return {
			title: "legal.title.terms" as const,
			type: Legal.TERMS_AND_CONDITIONS,
		};
	},
	notFoundComponent: () => {
		// eslint-disable-next-line react-hooks/rules-of-hooks -- This is a component, but eslint doesn't recognise it
		const navigate = useNavigate();

		navigate({
			params: termsAndConditionsRoute.useParams(),
			to: termsAndConditionsRoute.to,
		});

		return null;
	},
	path: "terms-and-conditions",
	staticData: {
		analyticsTitle: "Terms and Conditions",
	},
});

export const termsAndConditionsIndexRoute = createRoute({
	component: lazyRouteComponent(() => import("~/pages/Legal/TermsConditionsPage"), "TermsConditionsPage"),
	getParentRoute: () => termsAndConditionsRoute,
	path: "/",
});

export const privacyPolicyRoute = createRoute({
	getParentRoute: () => legalLayout,
	loader: async () => {
		return {
			title: "legal.title.privacy" as const,
			type: Legal.PRIVACY_POLICY,
		};
	},
	notFoundComponent: () => {
		// eslint-disable-next-line react-hooks/rules-of-hooks -- This is a component, but eslint doesn't recognise it
		const navigate = useNavigate();

		navigate({
			params: privacyPolicyRoute.useParams(),
			to: privacyPolicyRoute.to,
		});

		return null;
	},
	path: "privacy-policy",
	staticData: {
		analyticsTitle: "Privacy Policy",
	},
});

export const privacyPolicyIndexRoute = createRoute({
	component: lazyRouteComponent(() => import("~/pages/Legal/PrivacyPolicyPage"), "PrivacyPolicyPage"),
	getParentRoute: () => privacyPolicyRoute,
	path: "/",
});
