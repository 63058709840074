import { type QueryKey } from "@tanstack/react-query";

import { LeaderboardKey } from "~/enums";
import { queryClient } from "~/lib/queryClient";

import { getProfileQueryKey, type ProfileResponse } from "../profile";
import { type ActivityTrack } from "../profile/activities";
import {
	buildApiUrl,
	getCacheRemovalSet,
	getPreviousQueriesData,
	getSafeQueryData,
	handleNewCacheContentCreation,
	type OptimisticContext,
} from "../profile/utilities";

import { getAllLeadersQueryKey } from "./all-leaders";
import { getAllLeadersUrl } from "./all-leaders/all-leaders.api";
import { type LeaderboardPerson, type LeaderboardResponse } from "./leaderboard.schema";
import { getTopLeadersQueryKey } from "./top-leaders";
import { getTopLeadersUrl } from "./top-leaders/top-leaders.api";
import { getBaseLeaderboardQueryKey } from "./utilities";

const LEADERBOARD_KEYS = [LeaderboardKey.MONTHLY, LeaderboardKey.OVERALL] as const;

export const getApiUrl = (partialUrl: string, leaderboardKey: LeaderboardKey) => {
	const url = buildApiUrl(partialUrl);

	url.searchParams.append("leaderboardKey", leaderboardKey);

	return url.toString();
};

export const getOptimisticData = (queryKey: QueryKey, getUrl: () => string) => {
	const originalQueries = queryClient.getQueriesData<LeaderboardResponse>({
		exact: true,
		queryKey,
		type: "all",
	});

	const previousQueries = getPreviousQueriesData(originalQueries, getUrl);
	const safeQueries = getSafeQueryData(originalQueries, queryKey, []);
	const cacheRemovalData = getCacheRemovalSet(safeQueries.noExistingCacheKeys, getUrl);

	return {
		cacheRemovalData,
		data: safeQueries.safeData.flat()[1] as LeaderboardResponse,
		originalQueries,
		previousQueries,
		safeQueries,
	};
};

const sortLeaderboardData = (data: LeaderboardResponse) => {
	const newData = [...data];

	newData.sort((a, b) => Number(b.points) - Number(a.points));

	return newData;
};

export const getOptimisticLeaderboardQueryData = async <
	TContext extends OptimisticContext<LeaderboardResponse | undefined>,
>({
	points,
}: ActivityTrack) => {
	const profile = queryClient.getQueryData<ProfileResponse>(getProfileQueryKey());

	const queryInfo = {
		onSuccessInvalidationKeys: [],
		previousQueries: [],
		toRemoveOnError: [],
	} as unknown as TContext;

	if (points === 0 || !profile) {
		return queryInfo;
	}

	await queryClient.cancelQueries({ queryKey: getBaseLeaderboardQueryKey(), type: "all" });

	for await (const leaderboardKey of LEADERBOARD_KEYS) {
		const getTopUrl = () => getApiUrl(getTopLeadersUrl(), leaderboardKey);
		const getAllUrl = () => getApiUrl(getAllLeadersUrl(), leaderboardKey);

		const topLeaders = getOptimisticData(getTopLeadersQueryKey(leaderboardKey), getTopUrl);
		const allLeaders = getOptimisticData(getAllLeadersQueryKey(leaderboardKey), getAllUrl);

		const mergedLeadersData = [...topLeaders.data, ...allLeaders.data];
		const tempCurrentLeaderData = mergedLeadersData.find(({ isCurrentUser }) => isCurrentUser);

		const currentUserData = {
			avatarUrl: profile.avatarUrl,
			id: profile.id,
			isCurrentUser: true,
			name: profile.name,
			points: Number(tempCurrentLeaderData?.points ?? 0) + points,
			position: 1, // position will be recalculated
		} satisfies LeaderboardPerson;

		const sortedData = sortLeaderboardData([
			...mergedLeadersData.filter(({ isCurrentUser }) => !isCurrentUser),
			currentUserData,
		]);

		const newLeaderData = sortedData.map((leader, index) => ({
			...leader,
			position: index + 1,
		}));

		const topLeadersOnSuccessInvalidationKeys = await handleNewCacheContentCreation(
			topLeaders.safeQueries.safeData,
			() => newLeaderData.slice(0, 3),
			getTopUrl,
		);

		const allLeadersOnSuccessInvalidationKeys = await handleNewCacheContentCreation(
			allLeaders.safeQueries.safeData,
			() => newLeaderData.slice(3, newLeaderData.length),
			getAllUrl,
		);

		queryInfo.onSuccessInvalidationKeys.push(
			...topLeadersOnSuccessInvalidationKeys,
			...allLeadersOnSuccessInvalidationKeys,
		);
		queryInfo.previousQueries.push(...topLeaders.previousQueries, ...allLeaders.previousQueries);
		queryInfo.toRemoveOnError.push(...topLeaders.cacheRemovalData, ...allLeaders.cacheRemovalData);
	}

	return queryInfo;
};
