import { NapierBridgeCommon } from "@evotix/napier-ui-common-native-bridge";

import { AnalyticsEventActions, AnalyticsEventTypes } from "~/enums";

import { trackAnalyticsEvent } from "../analytics";

export type AppStateChangeListener = Parameters<typeof NapierBridgeCommon.addListener<"appStateChange">>[1];
export type NetworkStatusChangeListener = Parameters<typeof NapierBridgeCommon.addListener<"networkStatusChange">>[1];

const appStateChangeListener: AppStateChangeListener = (appState) => {
	trackAnalyticsEvent({
		action: appState.isActive ? AnalyticsEventActions.RESUMED : AnalyticsEventActions.PAUSED,
		type: AnalyticsEventTypes.APP,
	});
};

const createNetworkStatusChangeListener = (): NetworkStatusChangeListener => {
	let previousConnectedState: boolean | undefined;

	return (networkStatus) => {
		if (previousConnectedState === networkStatus.connected) return;

		previousConnectedState = networkStatus.connected;
		trackAnalyticsEvent({
			action: networkStatus.connected ? AnalyticsEventActions.ONLINE : AnalyticsEventActions.OFFLINE,
			type: AnalyticsEventTypes.APP,
		});
	};
};

const setupNativeAnalyticsListeners = async () => {
	const networkStatusChangeListener = createNetworkStatusChangeListener();

	await NapierBridgeCommon.addListener("appStateChange", appStateChangeListener);
	await NapierBridgeCommon.addListener("networkStatusChange", networkStatusChangeListener);
};

type InstallationStateCallback = Parameters<typeof NapierBridgeCommon.isInstallationStateChanged>[0];

const installationStateCallback: InstallationStateCallback = (state) => {
	trackAnalyticsEvent({
		action: state,
		interactive: false,
		label: WATT_VERSION,
		type: AnalyticsEventTypes.APP,
	});
};

export const setupNativeAnalytics = async () => {
	setupNativeAnalyticsListeners();
	NapierBridgeCommon.isInstallationStateChanged(installationStateCallback);
};
