import { buildUrl } from "~/lib/api/utilities";
import { wretch } from "~/lib/external/wretch";

import { levelSchema } from "./level.schema";

const getUrl = (id: number) => buildUrl(`level/${id}`, 1);

const getLevel = (id: number) =>
	wretch()
		.headers({
			"Content-Type": "application/json",
		})
		.napierNativeCache()
		.get(getUrl(id))
		.json(levelSchema.parseAsync);

type GetLevelResponse = Awaited<ReturnType<typeof getLevel>>;

export { getLevel, getUrl, type GetLevelResponse };
