import { type LeaderboardKey } from "~/enums";
import { wretch } from "~/lib/external/wretch";

import { buildUrl } from "../../utilities";
import { leaderboardResponseSchema } from "../leaderboard.schema";

const getUrl = () => buildUrl("activities/leaderboard/top", 1);

const getTopLeaders = (leaderboardKey: LeaderboardKey) =>
	wretch()
		.headers({
			"Content-Type": "application/json",
		})
		.napierNativeCache()
		.query({ leaderboardKey })
		.get(getUrl())
		.json(leaderboardResponseSchema.parseAsync);

type GetTopLeadersResponse = Awaited<ReturnType<typeof getTopLeaders>>;

export { getTopLeaders, getUrl as getTopLeadersUrl, type GetTopLeadersResponse };
