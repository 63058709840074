import { z } from "zod";

const interestSchema = z.object({
	id: z.number(),
	title: z.string(),
});

const interestsSchema = z.array(interestSchema).catch([]);

type InterestsResponse = z.infer<typeof interestsSchema>;

const interestsSearchSchema = z.object({
	filters: z.array(z.number()).optional(),
});

export { interestsSchema, type InterestsResponse, interestsSearchSchema };
