import { wretch } from "~/lib/external/wretch";
import { convertKeysToSnakeCase } from "~/utilities/camel-to-snake-case";

import { type AnalyticsEventPayload } from "../../../../types/application/analytics";
import { buildUrl } from "../../utilities";

const getUrl = () => buildUrl("analytics/event", 1);

const post = (data: AnalyticsEventPayload) => {
	const payload = convertKeysToSnakeCase(data);
	return wretch()
		.headers({
			"Content-Type": "application/json",
		})
		.post(payload, getUrl())
		.res();
};

export { post as postAnalyticsEvent };
