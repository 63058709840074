import { z } from "zod";

import { decodeEntities } from "~/utilities/decode-entities";
import { sanitiseUnderscoreString } from "~/utilities/sanitise-underscore-string";

import { cacheableMediaUrlSchema } from "../schemas/cacheableMediaUrl";

const featuredMediaSchema = z.object({
	downloadUrl: cacheableMediaUrlSchema().nullable(),
	//Allow null as they can have featuredMedia with no posterUrl
	posterUrl: cacheableMediaUrlSchema().nullable(),
	// Admin can determine what upload type is allowed, so we don't want to restrict this to specific types
	type: z.string().nullable().catch(null),
	url: cacheableMediaUrlSchema().nullable(),
});

type FeaturedMedia = z.infer<typeof featuredMediaSchema>;

const titleSchema = z.string().transform(decodeEntities).transform(sanitiseUnderscoreString).nullable();

type TitleType = z.infer<typeof titleSchema>;

type TitleProp = { title: TitleType };

export { featuredMediaSchema, titleSchema, type FeaturedMedia, type TitleType, type TitleProp };
