import { isNative } from "@evotix/napier-ui-common-native-bridge/utilities";

import { globalConfig } from "~/lib/config";
import { generateHash } from "~/utilities/generate-hash";

import { logError } from "../logging";

export const isGainsightEnabled = () => globalConfig.isFeatureEnabled("enableGainsight") && !isNative();

const gainsightLogin = (gainsightId: string, userId: string | undefined, tenant: string) => {
	const maxRetries = 3;
	const retryDelay = 5000;
	let retries = 0;

	const retryOperation = () => {
		try {
			aptrinsic("set", "globalContext");
			aptrinsic("identify", { id: gainsightId, userId }, { id: tenant });
		} catch (error) {
			const customError = new Error(`Attempt ${retries + 1} failed: ${error}`);
			logError(customError, {
				componentStack: `id: ${gainsightId}, userId: ${userId}`,
			});
			retries++;
			if (retries < maxRetries) {
				const retryMessage = new Error(`Retrying in ${retryDelay / 1000} seconds`);
				logError(retryMessage, {
					componentStack: `id: ${gainsightId}, userId: ${userId}`,
				});
				setTimeout(retryOperation, retryDelay);
			}
		}
	};

	retryOperation();
};

export const UseGainsightLogin = (profileId: string | undefined, tenant: string) => {
	if (!isGainsightEnabled()) return;

	const loginToGainsight = async () => {
		const userId = await generateHash(tenant + profileId);

		if (userId) {
			gainsightLogin(globalConfig.get().keys.gainsight, userId, tenant);
		}
	};

	loginToGainsight();
};
