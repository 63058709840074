import { type QueryClient, type QueryKey, type UseQueryOptions } from "@tanstack/react-query";

import { type LearningGroupResponse } from "../../learning/learning.schema";
import { type MissionsResponse } from "../../learning/missions";
import { type PathwaysResponse } from "../../learning/pathways";

export const MAXIMUM_BATCHED_REQUESTS = 30 as const;

/**
 * Will split out promises into batches and iterate over each batch one at a time
 * @param data
 * @example
 * await batchAndSettleRequests([new Promise(), new Promise()])
 */
export const batchAndSettleRequests = async <T extends Promise<void>>(data: T[]) => {
	const batches = [] as T[][];

	for (let index = 0; index < data.length; index += MAXIMUM_BATCHED_REQUESTS) {
		batches.push(data.slice(index, index + MAXIMUM_BATCHED_REQUESTS));
	}

	for await (const batch of batches) {
		await Promise.allSettled(batch);
	}
};

/**
 * Will get data from the query client and prefetch data using ids in an array, or ids in a children property if an object.
 * This works on the assumption that there will be data in the queryCache based on the initialDataQueryKey
 * @param queryClient
 * @param pendingRequests
 * @param initialDataQueryKey
 * @param prefetchOptionsGetter
 * @example
 * buildPendingPrefetchPromises<MissionsResponse>(
		queryClient,
		pendingRequests,
		getMissionsQueryKey(),
		getMissionBaseQueryOptions,
	);
 */
export const buildPendingPrefetchPromises = <
	TResponse extends LearningGroupResponse | MissionsResponse | PathwaysResponse,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any -- Not important here
	TOptionsGetter extends (...args: any[]) => UseQueryOptions<TResponse, Error> = any,
>(
	queryClient: QueryClient,
	pendingRequests: Promise<void>[],
	initialDataQueryKey: QueryKey,
	prefetchOptionsGetter: TOptionsGetter,
) => {
	queryClient.getQueriesData<TResponse>({ queryKey: initialDataQueryKey }).forEach(([_, data]) => {
		if (!data) return;

		if (Array.isArray(data)) {
			data.forEach(({ id }) => {
				pendingRequests.push(queryClient.prefetchQuery(prefetchOptionsGetter(id)));
			});

			return;
		}

		data.children.forEach(({ id }) => {
			pendingRequests.push(queryClient.prefetchQuery(prefetchOptionsGetter(id)));
		});
	});
};
