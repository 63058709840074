import { generateHexUuid } from "~/utilities/generate-hex-uuid";

import { type AnalyticsEvent } from "../../types/application/analytics";
import { postAnalyticsEvent } from "../api/analytics/event";
import { applicationContext } from "../context/application-context";
import { logError } from "../external/logging";

import { getPageviewId } from "./page-view";

export const trackAnalyticsEvent = (event: AnalyticsEvent) => {
	try {
		const pageview = getPageviewId();
		const timestamp = Date.now();
		const id = generateHexUuid();
		const connectionType = applicationContext.connection.connectionType;
		const embeddedPlatform = "none";
		postAnalyticsEvent({ ...event, connectionType, embeddedPlatform, id, pageview, timestamp });
	} catch (error) {
		logError(error, { extra: { debugInfo: "Analytics - page view" } });
	}
};
